import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";

import Question from '../question_comp/Question';
import { render } from 'react-dom';

var questions = [

  {question: "Која е јачината на <span className='davidoff_logo'></span> BLUE?", answers: [{text: "А: Катран 6, Никотин 0.5", stateClass: "trueAnswer"},{text: "Б: Катран 8, Никотин 0.3", stateClass: "falseAnswer"}, {text: "Б: Катран 6, Никотин 0.3", stateClass: "falseAnswer"}], imageClass: "cig-blue"},
  {question: "Која е јачината на <span className='davidoff_logo'></span>  SILVER?", answers: [{text: "А: Катран 4, Никотин 0.5", stateClass: "falseAnswer"},{text: "Б: Катран 4, Никотин 0.3", stateClass: "trueAnswer"}, {text: "Б: Катран 6, Никотин 0.5", stateClass: "falseAnswer"}], imageClass: "cig-silver"},
  {question: "Каков е филтерот на <span className='davidoff_logo'></span>  цигарите?", answers: [{text: "Стандарден", stateClass: "falseAnswer"},{text: "Firm - touch (цврст)", stateClass: "trueAnswer"}, {text: "Филтер со ментол капсула", stateClass: "falseAnswer"}] , imageClass: "filter"},
  {question: "Каков е тутунот на <span className='davidoff_logo'></span>  цигарите?", answers: [{text: "Мешавина од врвни тутуни", stateClass: "trueAnswer"},{text: "Тутун со арома на чоколадо", stateClass: "falseAnswer"}, {text: "Ориентален тутун", stateClass: "falseAnswer"}] , imageClass: "tobacco"},
  {question: "Специјалната хартија на <span className='davidoff_logo'></span>  цигарите придонесува за:", answers: [{text: "Поинтензивен мирис на дим", stateClass: "falseAnswer"},{text: "Намален мирис на дим", stateClass: "trueAnswer"}, {text: "Побрзо горење на цигарата", stateClass: "falseAnswer"}] , imageClass: "less-smoke"},
  {question: "Која е цената на <span className='davidoff_logo'></span>  цигарите?", answers: [{text: "110 ден.", stateClass: "falseAnswer"},{text: "100 ден.", stateClass: "trueAnswer"}, {text: "150ден.", stateClass: "falseAnswer"}] , imageClass: "cena"}
]

export default function Container() {
    const [currentquestion,setQuestion] = useState({question: "Кои варијанти на <span className='davidoff_logo'></span>  цигарите постојат?", answers: [{text: "Црвена и Сина", stateClass: "falseAnswer"},{text: "Сребрена и Бела", stateClass: "falseAnswer"}, {text: "Сина и Сребрена", stateClass: "trueAnswer"}] , imageClass: "cigs"})
        return(
    <Router>
      <div>
        <Switch>
          <Route path="/question">
            <Question  answers = {currentquestion.answers} question = {currentquestion.question} image={currentquestion.imageClass} finishedQuestion={()=>{
              console.log(questions.length);
              if(questions.length > 0){
                setQuestion(questions.shift());
              } else {
                setTimeout(() => {
                  window.location.href = '/end';
                }, 300);
              }
            }}/>
          </Route>
          <Route path="/end">
            <End />
          </Route>
          <Route path="/">
            <Welcome />
          </Route>
        </Switch>
      </div>
    </Router>
            
        );


        function Welcome() {
            return(
                <div className="main welcome">
                <div class="row">
                </div>

                <a className="button-next" href="#">
                  <Link to="/question">Next</Link>
                </a>

                </div>
            )
        }

        function End() {
            return(
                <div className="main end">
                <div class="row">
                  <h1>Честитки!</h1>
                </div>

                <a className="button-next" href="#">
                  <Link to="/welcome">Next</Link>
                </a>

                </div>
            )
        }

       
    
}
