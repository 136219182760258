import React, { useState } from 'react';

class Answer extends React.Component{

    constructor(props){
        super(props);
        this.state = {answered: false}
    }

    render(){
        var answeredClass = "single_answer ";
        if(this.state.answered){
            answeredClass += this.props.answerClass;
        }
        return(
            <span className={answeredClass}
                onClick={()=>{
                    if(!this.props.locked){
                    this.setState({answered:true});
                    this.props.answeredQuestion(this.props.answerClass);
                    setTimeout(()=>{
                        this.setState({answered: false});
                    },1000);
                    }
                }}>
                <h1>{this.props.answer}</h1>
            
            </span>
        )
    }

}

export default Answer;