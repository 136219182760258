import React, { useState } from 'react';
import Answer from './Answer';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

class Question extends React.Component{

    constructor(props){
        super(props);
        this.state = {answered: false, locked: false};
    }

    render(){
        var answers = [];
        for(var i = 0; i < this.props.answers.length; i++){
            answers.push(<Answer answer = {this.props.answers[i].text} locked={this.state.locked} answerClass={this.props.answers[i].stateClass} answeredQuestion={(answ)=>{
                this.setState({locked: true});
                setTimeout(()=>{
                    if(answ == "trueAnswer"){
                    this.props.finishedQuestion();
                    }
                    this.setState({locked: false});
                },1000);
            }}/>)   
        }
        return(
            <div className="main_question_area">
            <div class="row question_holder">

              <div className="question_area">
        <span className="single_question"><h1 dangerouslySetInnerHTML={{__html: this.props.question}} /></span>
              </div>
              <div className="answer_area_wrap">
              <div className="answer_area">
                {
                   answers
                }
              </div>
              </div>
            </div>
            <span id="right_image" className={this.props.image}></span>

            </div>
        )
    }

}

export default Question;