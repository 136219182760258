import React from 'react';
import { DndProvider } from 'react-dnd-cjs';
import { DragDropContext } from 'react-dnd-cjs';
import HTML5Backend from 'react-dnd-html5-backend-cjs';
import './App.css';
import FirstScreen from '../firstscreen/FirstScreen';
import TouchBackend from 'react-dnd-touch-backend-cjs';
import MultiBackend, {Preview, TouchTransition} from 'react-dnd-multi-backend';
import objectAssign from 'object-assign';

function generatePreview(type, item, style) {
  objectAssign(style, {backgroundColor: item.color, width: '140px', height: '140px', fontSize: '0.5em'});
return (<div style={style} id={item.cardBackground}><h1>{item.description}</h1></div>);
}

function App() {
  const opt = {
    enableMouseEvents: true
  }

  const HTML5toTouch = {
    backends: [
      {
        backend: HTML5Backend
      },
      {
        backend: TouchBackend, // Note that you can call your backends with options
        preview: true,
        transition: TouchTransition
      }
    ]
  };
  

  return (
    <div className="App">
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
          <FirstScreen />
          <Preview generator={generatePreview} />
			</DndProvider>
      <div class="triangle"></div>
      {/* <DndProvider backend={HTML5Backend}>
          <SecondScreen />
			</DndProvider>

      <DndProvider backend={HTML5Backend}>
          <ThirdScreen />
			</DndProvider>

      <DndProvider backend={HTML5Backend}>
          <FourthScreen />
			</DndProvider> */}
      <div className="rotator"></div>
    </div>
  );
}

export default App;